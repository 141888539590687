<template>
    <NavBar />
    <div>
        <div class="container mx-auto p-5" style="max-width: 690px;">
            <div class="flex justify-center">
                <div class="w-full md:w-1/2">
                    <div class="text-center">
                        <h1 class="text-3xl font-bold">Change Password</h1>
                    </div>
                    <form class="mt-4" @submit.prevent="changePassword">
                        <div class="mb-4">
                            <label for="oldpassword" class="block text-gray-700 text-sm font-bold mb-2">Old Password</label>
                            <InputText type="password" id="oldpassword" v-model="currentPass"
                                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none" />
                            <small class="p-error" v-if="v$.currentPass.$error">Old password is Required</small>
                        </div>
                        <div class="mb-4">
                            <label for="newpassword" class="block text-gray-700 text-sm font-bold mb-2">New Password</label>
                            <InputText type="password" id="newpassword" v-model="newPass"
                                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none" />
                            <small class="p-error" v-if="v$.newPass.$error">New password is Required</small>
                        </div>
                        <div class="mb-4">
                            <label for="confirmpassword" class="block text-gray-700 text-sm font-bold mb-2">Confirm
                                Password</label>
                            <InputText type="password" id="confirmpassword" v-model="confirmPass"
                                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none" />
                            <small class="p-error" v-if="v$.confirmPass.$error">Confirm password is Required</small>
                        </div>
                        <div class="mb-4">
                            <Button class="w-full block" severity="danger" outlined type="submit">Change Password</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import axios from 'axios';
export default {
    name: 'ChangePassword',
    components: {
        NavBar
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            currentPass: '',
            newPass: '',
            confirmPass: ''
        }
    },
    validations() {
        return {
            currentPass: {
                required
            },
            newPass: {
                required
            },
            confirmPass: {
                required
            }
        }
    },
    methods: {
        async changePassword() {
            try {
                const result = await this.v$.$validate();
                if (!result) return;
                if (this.newPass !== this.confirmPass) {
                    this.$toast.add({ severity: 'error', detail: "New password and confirm password should be same", group: 'br', life: 3000 });
                    return;
                }
                const res = await axios.post('changepassword', {
                    currentPass: this.currentPass,
                    newPass: this.newPass
                });
                if (res.data.success) {
                    this.$router.go(-1);
                }
                this.$toast.add({ severity: res.data.success ? 'success' : 'error', detail: res.data.message, group: 'br', life: 3000 });
            }
            catch (e) {
                console.error(e);
            }
        }
    }
}
</script>