<template>
    <NavBar></NavBar>
    <div class="claim">
        <div class="surface-card p-2 shadow-2">
            <form @submit.prevent="handleSubmit">
                <div class="grid">
                    <div class="col-6">
                        <Calendar placeholder="Month" class="w-full" v-model="claimFilterForm.month" view="month"
                            dateFormat="MM" :class="[v$.claimFilterForm.month.$error ? 'p-invalid' : '']" />
                        <small class="p-error" v-if="v$.claimFilterForm.month.$error">Month is Required</small>
                    </div>
                    <div class="col-6">
                        <Calendar placeholder="Year" class="w-full" v-model="claimFilterForm.year" view="year"
                            dateFormat="yy" :class="[v$.claimFilterForm.year.$error ? 'p-invalid' : '']" />
                        <small class="p-error" v-if="v$.claimFilterForm.year.$error">Year is Required</small>
                    </div>
                    <div class="col-12 text-center">
                        <Button type="submit" label="Show" :icon="Loading ? 'pi pi-spin pi-spinner' : ' pi pi-search'"
                            outlined>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        <Button class="mt-2" label="Add" icon="pi pi-plus" outlined @click="showPopup = true"></Button>

        <div class="mt-2" v-if="claimData">
            <ScrollPanel style="width: 100%; height: 285px" :pt="{
                wrapper: { style: 'border-right: 5px solid var(--surface-50); border-bottom: 5px solid var(--surface-50);' },
                bary: 'surface-300 opacity-100 border-noround'
            }">
                <table class="w-full table table-border">
                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount</th>

                    </tr>
                    <tr v-for="(item, index) in claimData" :key="item.ClaimId">
                        <td>{{ index + 1 }}</td>
                        <td>{{ $filters.formateDate(item.ClaimDate) }}</td>
                        <td>{{ item.Description }}</td>
                        <td>$ {{ item.Amount }}</td>
                    </tr>
                </table>
            </ScrollPanel>


        </div>

        <Dialog v-model:visible="showPopup" modal header="Add Claim" :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class="leaveForm">
                <form @submit.prevent="addClaim">
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="date">Claim Date</label>
                        </div>
                        <div class="col-8">
                            <Calendar id="date" v-model="claimForm.date" showIcon class="w-full"
                                :class="[v$.claimForm.date.$error ? 'p-invalid' : '']" />
                            <small class="p-error" v-if="v$.claimForm.date.$error">Date is Required</small>

                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="amount">Amount</label>
                        </div>
                        <div class="col-8">
                            <InputNumber id="amount" inputId="integeronly" v-model="claimForm.amount" class="w-full"
                                :class="[v$.claimForm.amount.$error ? 'p-invalid' : '']" />
                            <small class="p-error" v-if="v$.claimForm.amount.$error">Amount is Required</small>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="description">Description</label>
                        </div>
                        <div class="col-8">
                            <Textarea id="description" v-model="claimForm.description" rows="5" class="w-full" />
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="description">Upload Receipt</label>
                        </div>
                        <div class="col-8">
                            <InputText id="fileUpload" type="file" class="w-full" required />
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <Button type="submit" label="Save" :icon="popLoading ? 'pi pi-spin pi-spinner' : ' pi pi-check'"
                                size="large" outlined severity="success" />
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    </div>
    <Footer></Footer>
</template>



<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import axios from 'axios';

export default {
    name: 'ClaimView',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        NavBar,
        Footer
    },
    data() {
        return {
            claimFilterForm: {
                month: new Date(),
                year: new Date(),
            },
            claimForm: {
                date: new Date(),
                amount: null,
                description: null,
            },
            Loading: false,
            popLoading: false,
            showPopup: false,
            claimData: null,
            formHeader: {
                'Content-Type': 'multipart/form-data'
            },
            type: [
                { name: 'Leave', code: 'leave' },
                { name: 'MC', code: 'mc' },

            ]
        }
    },
    validations() {
        return {
            claimFilterForm: {
                month: { required },
                year: { required }
            },
            claimForm: {
                date: { required },
                amount: { required },
            }
        }
    },
    methods: {
        async handleSubmit() {
            this.Loading = true;
            const result = await this.v$.claimFilterForm.$validate();
            if (result) {
                let month = new Date(this.claimFilterForm.month).getMonth() + 1;
                let year = new Date(this.claimFilterForm.year).getFullYear();
                try {
                    const response = await axios.get('claims?request.year=' + year + '&request.month=' + month);
                    console.log(response)
                    if (response.status == 200) {
                        this.Loading = false;
                        if (response.data.success) {
                            this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 3000 });

                            this.claimData = response.data.result;
                        } else {
                            this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 3000 });
                        }
                    } else {
                        this.Loading = false;
                    }
                } catch (error) {
                    this.Loading = false;
                }

            } else {
                this.Loading = false;
            }
        },
        async addClaim() {
            this.popLoading = true;
            const result = await this.v$.claimForm.$validate();
            var formData = new FormData();
            var imagefile = document.querySelector('#fileUpload');
            formData.append("image", imagefile.files[0]);
            if (result) {
                formData.append("postData", JSON.stringify({ ClaimDate: this.claimForm.date, Description: this.claimForm.description, Amount: this.claimForm.amount }));

                try {
                    const response = await axios.post('claims', formData, this.formHeader);
                    if (response.status == 200) {
                        this.popLoading = false;
                        if (response.data.success) {
                            this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 3000 });
                            this.showPopup = false;
                            this.handleSubmit();

                        } else {
                            this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 3000 });
                        }
                    } else {
                        this.popLoading = false;
                    }
                } catch (error) {
                    this.popLoading = false;
                }

            } else {
                this.popLoading = false;
            }
        }
    }

}
</script>
