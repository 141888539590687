<template>
    <div class="header mb-2">
        <div class="navbarWrapper ">
            <div class="grid surface-0 shadow-2 pt-2 pb-2">
                <div class="col-4 pt-0 pb-0  text-start">
                    <RouterLink to="/">
                        <img src="../assets/images/logo.png" width="50">
                    </RouterLink>
                </div>
                <div class="col-8 pt-0 pb-0">
                    <div class="card flex justify-content-end align-items-center">
                        <span class="mr-2 font-bold">{{this.userName }}</span>
                        <Button @click="handleLogout" icon="pi pi-sign-out" severity="danger" text  aria-label="Logout" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NavBar',
    data() {
        return {
            accountItems: [
                {
                    label: 'Change Password',
                    icon: 'pi pi-box',
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-box',
                    command: () => {
                        this.handleLogout()
                    }
                }
            ],
            appMenuItems: [
                {
                    label: 'Home',
                    icon: 'pi pi-home',
                    route: '/'
                },
                {
                    label: 'Record Attendance',
                    icon: 'pi pi-qrcode',
                    route: '/attendance',
                    //visible: this.IsAdmin
                },
                {
                    label: 'Roster',
                    icon: 'pi pi-calendar',
                    route: '/roster'
                },
                {
                    label: 'Leave And MC',
                    icon: 'pi pi-sign-out',
                    route: '/leave'
                },
                {
                    label: 'Payslip',
                    icon: 'pi pi-book',
                    route: '/payslip',

                },
                {
                    label: 'Claim/Reimburse',
                    icon: 'pi pi-replay',
                    route: '/claim'
                }
            ]
        };
    },
    methods: {
        toggleApp(event) {
            this.$refs.appMenu.toggle(event);
        },
        handleLogout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>
