<template>
  <div class="login flex align-items-center justify-content-center">
    <div class="border-round w-full lg:w-4 md:w-5 sm:w-8">
      <div class="text-center mb-2">
        <img src="../assets/images/logo.png" alt="Image" />
        <p class="loginText font-bold ">YUGUO-HRMS</p>
      </div>

      <div class="surface-card p-4 shadow-2">
        <form @submit.prevent="handleLogin">
          <label for="Username" class="block text-900 font-medium mb-2">Username</label>
          <InputText id="Username" type="text" class="w-full" size="large" v-model="loginForm.email"
            :class="[v$.loginForm.email.$error ? 'p-invalid' : '']" />
          <div class="p-error" v-if="v$.loginForm.email.$error"><small>UserName is Required</small></div>

          <label for="password1" class="block text-900 font-medium mb-2 mt-3">Password</label>
          <InputText id="password1" type="password" class="w-full " size="large" v-model="loginForm.password"
            :class="[v$.loginForm.password.$error ? 'p-invalid' : '']" />
          <div class="p-error" v-if="v$.loginForm.password.$error" severity="error"><small>Password is Required</small>
          </div>
          <Button type="submit" label="Sign In" class="w-full justify-content-center mt-3" :icon="loading ? 'pi pi-spin pi-spinner':'pi pi-sign-in'" size="large"></Button>
        </form>

      </div>
    </div>
  </div>
</template>
  
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

//import axios from "axios";
export default {
  name: 'LoginView',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: false,
      loginForm: {
        email: "",
        password: ""
      }
    }
  },
  validations() {
    return {
      loginForm: {
        email: { required },
        password: { required }
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    async handleLogin() {
      this.loading = true
      const result = await this.v$.$validate();
      if (result) {
        this.$store.dispatch('auth/login', this.loginForm).then(
          (data) => {
            console.log(data)
            if (data.success) {
              this.$toast.add({ severity: 'success', detail: data.message, group: 'br', life: 3000 });
              let user = JSON.parse(localStorage.getItem('user'));
              let token = null;
              if(user != null){
                token = user.Token
              }
              if (token) {
                window.location.href = "/";
              }
              this.loading = false
            } else {
              this.loading = false
              this.$toast.add({ severity: 'error', detail: data.message, group: 'br', life: 3000 });
            }
          },
          (error) => {
            this.loading = false
            this.$toast.add({ severity: 'error', detail: error.message, group: 'br', life: 3000 });
            console.log(error)
          }
        );
      } else {
        this.loading = false

      }

    },

    //handleLogin() {
    // const response = await axios.post('login', {
    //   UserName: this.loginForm.email,
    //   Password: this.loginForm.password
    // })
    // console.log(response);
    // localStorage.setItem('token', response.data.result.Token)
    // this.$router.push('/')
    //}
  }
}
</script>
  