import axios from 'axios';

class AuthService {
  login(user) {
    return axios.post('login', {
      UserName: user.email,
      Password: user.password
    })
      .then(response => {
        if (response.status == 200) {
          if (response.data.success && response.data.result.Token) {
            localStorage.setItem('user', JSON.stringify(response.data.result));
          }
          return response.data;
        }
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
