<template>
    <div class="footer">
        <Accordion :activeIndex="[0]">
            <AccordionTab header="Menu">
                <div class="flex flex-wrap justify-content-center gap-2 ">
                    <router-link to="/">
                        <Button label="Home" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/markattandance">
                        <Button label="Attendance" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/attendance" v-if="IsAdmin">
                        <Button label="QR Code" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/roster">
                        <Button label="Roster" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/leave">
                        <Button label="Leave And MC" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/payslip">
                        <Button label="Payslip" severity="info" raised outlined />
                    </router-link>
                    <router-link to="/claim">
                        <Button label="Claim/Reimburse" severity="info" raised outlined />
                    </router-link>
                </div>
            </AccordionTab>

        </Accordion>
     
    </div>
</template>


<script>
export default {
    name: 'NavBar',
}
</script>

<style>
.footer {
    position: fixed;
    width: calc(100% - 15px);
    bottom: 0;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px -2px 6px rgba(0, 0, 0, 0.12) !important;
    z-index: 99;
}
</style>
