import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AttendanceView from '../views/AttendanceView.vue'
import RosterView from '../views/RosterView.vue'
import PaySlip from '../views/PaySlipView.vue'
import LeaveView from '../views/LeaveView.vue'
import ClaimView from '../views/ClaimView.vue'
import RecordAttendanceView from '../views/RecordAttendance.vue'
import ChangePassword from "@/views/ChangePassword.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: AttendanceView
  },
  {
    path: '/markattandance',
    name: 'markattandance',
    component: RecordAttendanceView
  },
  {
    path: '/roster',
    name: 'roster',
    component: RosterView
  },
  {
    path: '/leave',
    name: 'leave',
    component: LeaveView
  },
  {
    path: '/claim',
    name: 'claim',
    component: ClaimView
  },
  {
    path: '/payslip',
    name: 'payslip',
    component: PaySlip
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePassword
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
