<template>
  <div class="home">
    <NavBar></NavBar>
    <div class="homeContent flex justify-content-center align-items-center ">
      <div class="card flex-1">
        <div class="grid align-items-center">
          <div class="col-10 m-auto">
            <div class="grid" id="admin" v-if="IsAdmin">
              <div class="col-12">
                <router-link to="/attendance">
                  <Button class="w-full" label="QR Code" severity="info" raised outlined icon="pi pi-qrcode"
                    size="large" />
                </router-link>
              </div>
            </div>
            <div class="grid" v-else>
              <div class="col-12">
                <router-link to="/markattandance">
                  <Button class="w-full" label="Attendance" severity="info" raised outlined icon="pi pi-qrcode"
                    size="large" />
                </router-link>
              </div>
              <div class="col-12">
                <router-link to="/roster">
                  <Button class="w-full" label="Roster" severity="info" raised outlined icon="pi pi-calendar"
                    size="large" />
                </router-link>
              </div>
              <div class="col-12">
                <router-link to="/leave">
                  <Button class="w-full" label="Leave And MC" severity="info" raised outlined icon="pi pi-sign-out"
                    size="large" />
                </router-link>
              </div>
              <div class="col-12">
                <router-link to="/payslip">
                  <Button class="w-full" label="Payslip" severity="info" raised outlined icon="pi pi-book" size="large" />
                </router-link>
              </div>
              <div class="col-12">
                <router-link to="/claim">
                  <Button class="w-full" label="Claim/Reimburse" severity="info" raised outlined icon="pi pi-replay"
                    size="large" />
                </router-link>
              </div>
            </div>
            <div class="grid">
              <div class="col-12">
                <router-link to="/changepassword">
                  <Button class="w-full" label="Change Password" severity="warning" raised outlined icon="pi pi-lock"
                    size="large" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
//import axios from 'axios';
export default {
  name: 'HomeView',
  data() {
    return {
      auth: null
    }
  },
  created() {
    // axios.get('authcheck')
    //   .then(function (response) {
    //     console.log('authcheck', response);

    //   })
    //   .catch(function (error) {
    //     console.log("authcheck", error);
    //   });

  },
  components: {
    NavBar
  }

}
</script>
