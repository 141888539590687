<template>
    <NavBar></NavBar>
    <div class="leave">
        <div class="surface-card p-2 shadow-2">
            <form @submit.prevent="handleSubmit">
                <div class="grid">
                    <div class="col-8">
                        <Calendar placeholder="Year" class="w-full" v-model="filterForm.year" view="year" dateFormat="yy"
                            :class="[v$.filterForm.year.$error ? 'p-invalid' : '']" />
                        <small class="p-error" v-if="v$.filterForm.year.$error">Year is Required</small>
                    </div>
                    <div class="col-4 text-end">
                        <Button type="submit" label="Show" :icon="Loading ? 'pi pi-spin pi-spinner' : ' pi pi-search'"
                            outlined>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        <Button class="mt-2" label="Add" icon="pi pi-plus" outlined @click="showPopup = true"></Button>
        <div class="mt-2" v-if="leaveData">
            <ScrollPanel style="width: 100%; height: 335px" :pt="{
                wrapper: { style: 'border-right: 5px solid var(--surface-50); border-bottom: 5px solid var(--surface-50);' },
                bary: 'surface-300 opacity-100 border-noround'
            }">
                <table class="w-full table table-border">
                    <tr>
                        <th>No</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Count</th>
                    </tr>
                    <tr v-for="(item, index) in leaveData" :key="index + 1">
                        <td>{{ index + 1 }}</td>
                        <td>{{ $filters.formateDate(item.StartDate) }}</td>
                        <td>{{ $filters.formateDate(item.EndDate) }}</td>
                        <td>{{ item.LeaveType }}</td>
                        <td>{{ item.Status }}</td>
                        <td>{{ item.LeaveCount }}</td>
                    </tr>


                </table>
                <div class="mt-2">
                    <h2>Summary Leave</h2>
                    <p class="font-bold">Leave Taken: {{ this.leaveTaken }} days</p>
                    <p class="font-bold">Leave Left: {{ this.leaveLeft }} days</p>
                    <p class="font-bold">Unpaid Leave: {{ this.unpaidLeaveTaken }} days</p>
                    <p class="font-bold">MC: {{ this.mcTaken }} days</p>
                </div>
            </ScrollPanel>
        </div>


        <Dialog v-model:visible="showPopup" modal header="Add Leave" :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class="leaveForm">
                <form @submit.prevent="addLeave">
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="startDate">Start Date</label>
                        </div>
                        <div class="col-8">
                            <Calendar id="startDate" v-model="leaveForm.startDate" showIcon class="w-full"
                                :class="[v$.leaveForm.startDate.$error ? 'p-invalid' : '']" />
                            <small class="p-error" v-if="v$.leaveForm.startDate.$error">Start Date is Required</small>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="endDate">End Date</label>
                        </div>
                        <div class="col-8">
                            <Calendar id="endDate" v-model="leaveForm.endDate" showIcon class="w-full"
                                :class="[v$.leaveForm.endDate.$error ? 'p-invalid' : '']" />
                            <small class="p-error" v-if="v$.leaveForm.endDate.$error">End Date is Required</small>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="type">Type</label>
                        </div>
                        <div class="col-8">
                            <Dropdown v-on:change="changeType" v-model="leaveForm.type" :options="type" optionLabel="name"
                                placeholder="Select a Type" class="w-full"
                                :class="[v$.leaveForm.type.$error ? 'p-invalid' : '']" />
                            <small class="p-error" v-if="v$.leaveForm.type.$error">Leave Type is Required</small>

                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="count">Count</label>
                        </div>
                        <div class="col-8">
                            <InputGroup>
                                <InputNumber :minFractionDigits="0" :maxFractionDigits="1" id="count"
                                    v-model="leaveForm.count" class="w-full"
                                    :class="[v$.leaveForm.count.$error ? 'p-invalid' : '']" />
                                <InputGroupAddon>Day</InputGroupAddon>
                            </InputGroup>
                            <small class="p-error" v-if="v$.leaveForm.count.$error">Count is Required</small>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-4 flex align-items-center">
                            <label for="description">Description</label>
                        </div>
                        <div class="col-8">
                            <Textarea id="description" v-model="leaveForm.description" rows="5" class="w-full" />
                        </div>
                    </div>
                    <div v-if="leaveForm.type">
                        <div class="grid" v-if="leaveForm.type.code == 'MC'">
                            <div class="col-4 flex align-items-center">
                                <label for="description">Upload MC</label>
                            </div>
                            <div class="col-8">
                                <InputText id="fileUpload" type="file" class="w-full"
                                    :required="filerequired ? true : false" />
                            </div>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <Button type="submit" label="Save" size="large" outlined severity="success"
                                :icon="leaveLoading ? 'pi pi-spin pi-spinner' : ' pi pi-check'" />
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    </div>
    <Footer></Footer>
</template>



<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import axios from 'axios';

export default {
    name: 'LeaveView',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        NavBar,
        Footer
    },
    data() {
        return {
            filterForm: {
                year: new Date(),
            },
            leaveForm: {
                startDate: new Date(),
                endDate: new Date(),
                type: null,
                count: null,
                description: null
            },
            formHeader: {
                'Content-Type': 'multipart/form-data'
            },
            Loading: false,
            showPopup: false,
            leaveLoading: false,
            leaveData: null,
            leaveTaken: 0,
            leaveLeft: 0,
            unpaidLeaveTaken: 0,
            mcTaken: 0,
            filerequired: false,
            type: [
                { name: 'Leave', code: 'Leave' },
                { name: 'MC', code: 'MC' },
                { name: 'Unpaid Leave', code: 'Unpaid' },
            ]
        }
    },
    validations() {
        return {
            filterForm: {
                year: { required }
            },
            leaveForm: {
                startDate: { required },
                endDate: { required },
                type: { required },
                count: { required }
            }
        }
    },
    methods: {
        async handleSubmit() {
            this.Loading = true;
            const result = await this.v$.filterForm.$validate();
            if (result) {
                let year = new Date(this.filterForm.year).getFullYear();
                try {
                    const response = await axios.get('leaves?year=' + year);
                    if (response.status == 200) {
                        this.Loading = false;
                        if (response.data.success) {
                            this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 3000 });
                            this.leaveTaken = response.data.result.leaveTaken;
                            this.leaveLeft = response.data.result.leaveLeft;
                            this.unpaidLeaveTaken = response.data.result.unpaidLeaveTaken;
                            this.mcTaken = response.data.result.mcTaken;
                            this.leaveData = response.data.result.leaves;
                        } else {
                            this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 3000 });
                        }
                    } else {
                        this.Loading = false;
                    }
                } catch (error) {
                    this.Loading = false;
                }
            } else {
                this.Loading = false;
            }
        },
        async addLeave() {
            this.leaveLoading = true;
            const result = await this.v$.leaveForm.$validate();
            if (result) {

                var formData = new FormData();
                if (this.leaveForm.type.code == "MC") {
                    var imagefile = document.querySelector('#fileUpload');
                    formData.append("image", imagefile.files[0]);
                }
                formData.append("postData", JSON.stringify({ StartDate: this.leaveForm.startDate, EndDate: this.leaveForm.endDate, LeaveType: this.leaveForm.type.code, LeaveCount: this.leaveForm.count, Description: this.leaveForm.description }));

                try {
                    const response = await axios.post('leaves', formData, this.formHeader);
                    if (response.status == 200) {
                        this.leaveLoading = false;
                        if (response.data.success) {
                            this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 3000 });
                            this.showPopup = false;
                            this.handleSubmit();

                        } else {
                            this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 3000 });
                        }
                    } else {
                        this.leaveLoading = false;
                    }
                } catch (error) {
                    this.leaveLoading = false;
                }

            } else {
                this.leaveLoading = false;
            }
        },
        changeType() {
            if (this.leaveForm.type.code == "MC") {
                this.filerequired = true
            } else {
                this.filerequired = false

            }
        }
    }

}
</script>
