import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import './assets/interceptors/axios'



import 'primevue/resources/themes/lara-light-blue/theme.css'
import '/node_modules/primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import './assets/style.css'


import Button from "primevue/button"
import InputText from 'primevue/inputtext';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';

import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import ScrollPanel from 'primevue/scrollpanel';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';





import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';







const app = createApp(App);
app.use(ToastService);
app.component('Toast', Toast);

app.component('Button', Button);
app.component('InputText', InputText);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Calendar', Calendar);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Textarea', Textarea);
app.component('InputNumber', InputNumber);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('ScrollPanel', ScrollPanel);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);






app.config.globalProperties.$filters = {
    formateDate(value) {
        var date = new Date(value);
        var result = (date.getDate() > 9 ? '' : '0') + date.getDate() + " " + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + " " + date.getFullYear();
        return result
    },
    formateTime(value) {
        var date = new Date(value);
        var result = (date.getHours() > 9 ? '' : '0') + date.getHours() + " : " + ((date.getMinutes() + 1) > 9 ? '' : '0') + (date.getMinutes());
        return result
    }
}
let user = JSON.parse(localStorage.getItem('user'))
if (user != null) {
    app.config.globalProperties.userName = user.FullName
    app.config.globalProperties.IsAdmin = user.Role == "SuperAdmin";

}

app.use(PrimeVue)
app.use(store)
app.use(router)



app.mount('#app')
